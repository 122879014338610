import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { Container, Box, makeStyles } from '@material-ui/core'
import MitPensumLogo from '../../svg/mitpensum-logo.svg'
import Link from 'next/link'

const useStyles = makeStyles(() => ({
  link: {
    cursor: 'pointer',
    display: 'inline-block'
  }
}))
export const LandingpageNavbar: React.FC = () => {
  const classes = useStyles()
  return (
    <>
      <AppBar color={'transparent'} position={'sticky'} elevation={0}>
        <Box bgcolor='grey.50'>
          <Toolbar disableGutters={true}>
            <Container maxWidth={'lg'}>
              <Box>
                <Link href='/'>
                  <div className={classes.link}>
                    <MitPensumLogo />
                  </div>
                </Link>
              </Box>
            </Container>
          </Toolbar>
        </Box>
      </AppBar>
      <Toolbar />
    </>
  )
}
