import {
  Box,
  Container,
  Grid,
  Typography,
  Link as MaterialLink
} from '@material-ui/core'
import NextLink from 'next/link'
import { makeStyles } from '@material-ui/core'
import { CustomTheme } from '../../themes/main'

const useStyles = makeStyles<CustomTheme>((theme) => ({
  container: {
    backgroundColor: theme.palette.darkBlue.main,
    color: theme.palette.white.main
  }
}))

export const Footer: React.FC = () => {
  const classes = useStyles()
  return (
    <Box mt={16} className={classes.container}>
      <Container maxWidth={'lg'}>
        <Box py={6}>
          <Grid container direction={'row'} spacing={2}>
            <Grid item xs={12} lg={4}>
              <Typography variant='h4' color='inherit'>
                KONTAKT
              </Typography>
              <Typography color='inherit'>
                Mit Pensum er udarbejdet af SL Fonden
                <br />
                Rosenørns Allé 9, 1970 Frederiksberg C<br />
                CVR: 27485111
                <br />
                <NextLink
                  href={'mailto:academicbooks@academicbooks.dk'}
                  passHref
                >
                  <MaterialLink color='inherit'>
                    academicbooks@academicbooks.dk
                  </MaterialLink>
                </NextLink>
              </Typography>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Typography variant='h4' color='inherit'>
                GENVEJE
              </Typography>
              <Grid container direction='column'>
                <NextLink href={'/dashboard'} passHref>
                  <MaterialLink color='inherit'>Log ind</MaterialLink>
                </NextLink>
                <NextLink href={'/about-us'} passHref>
                  <MaterialLink color='inherit'>Om os</MaterialLink>
                </NextLink>
                <NextLink href={'/privacy-policy'} passHref>
                  <MaterialLink color='inherit'>Privatlivspolitik</MaterialLink>
                </NextLink>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}
